import Script from "next/script";
import "../styles/index.css";
import "../styles/style.scss";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script
        id="Adsense-id"
        data-ad-client="ca-pub-9502972669695969"
        async
        crossorigin="anonymous"
        strategy="lazyOnload"
        onError={(e) => {
          console.error("Script failed to load", e);
        }}
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />

      {/* <Script
        src="https://www.googletagmanager.com/gtag/js?id=GTM-TKSM6D7"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-TKSM6D7');
        `}
      </Script> */}

      <Script src="https://www.googletagmanager.com/gtag/js?id=G-3VEPCL9J86" strategy="afterInteractive"/>
      <Script id="google-analytics" strategy="afterInteractive">      
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-3VEPCL9J86');
        `}
      </Script>

      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
